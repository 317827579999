body {
  /* background-color: #f9f9f9; */
  background-color: #f1f1f1;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

header {
  position: relative;
  width: 100%;
}

.header-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  object-position: middle;
  border-radius: 4px;
}

.navbar {
  background-color: #f1f1f1;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
}

.navbar-list {
  display: flex;
  list-style: none;
  padding: 0;
}

.navbar-item {
  display: inline-block;
  margin-right: 10px;
}

.navbar-link {
  text-decoration: none;
  color: #333;
  padding: 5px 10px;
  border-radius: 4px;
}

.navbar-link:hover {
  background-color: #ddd;
}

.page {
  /* background-color: #f9f9f9; */
  padding: 20px;
  border-radius: 4px;
}

.title {
  margin-top: 0;
}

.description {
  margin-bottom: 20px;
}

.signup-container {
  max-width: 800px;
  /* margin: 0 auto; */
  /* padding: 20px; */
}

.iframe-container {
  position: relative;
  overflow: scroll;
  padding-top: 56.25%; /* Aspect ratio: 16:9 (height/width * 100%) */
  height: 0; /* Set initial height to 0 */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Use viewport height to cover the screen height */
  min-width: 100%; /* Ensures the iframe takes up at least 100% of the container's width */
}
